
import { controlConstants } from "@constants/control.constants";

const initialState = {
    isFetchingControl: false,
    formControl: null,
    control: null,

    isFetchingControls: false,
    isFetchingControlsFrom: 0,
    controlsTotalResults: 0,
    controlsLoaded: 0,
    controls: [],
};

export default function control(state = initialState, action) {
    switch (action.type) {
        case controlConstants.CONTROL_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case controlConstants.GET_CONTROL_STARTED:
            return { ...state, isFetchingControl: true };
        case controlConstants.GET_CONTROL_FAILURE:
            return { ...state, isFetchingControl: false, control: null, error: action.message || "" };
        case controlConstants.GET_CONTROL_SUCCESS:
            return {
                ...state,
                isFetchingControl: false,
                control: action.control,
            };
            
        case controlConstants.GET_CONTROLS_STARTED:
            return { ...state, isFetchingControls: true,  isFetchingControlsFrom: action.from || 0 };
        case controlConstants.GET_CONTROLS_FAILURE:
            return { ...state, isFetchingControls: false, controls: [], error: action.message || "" };
        case controlConstants.GET_CONTROLS_SUCCESS:
            return {
                ...state,
                isFetchingControls: false,
                controls: action.from > 0 ? [...state.controls, ...action.content] : action.content,
                controlsLoaded:
                    action.from > 0
                        ? state.controls.length + action.content.length
                        : action.content.length,
                controlsTotalResults: action.totalElements || 0,
            };

        case controlConstants.SEARCH_CONTROLS_STARTED:
            return { ...state, isFetchingControls: true, isFetchingControlsFrom: action.from || 0  };
        case controlConstants.SEARCH_CONTROLS_FAILURE:
            return { ...state, isFetchingControls: false, controls: [], error: action.message || "" };
        case controlConstants.SEARCH_CONTROLS_SUCCESS:
            return {
                ...state,
                isFetchingControls: false,
                controls: action.from > 0 ? [...state.controls, ...action.content] : action.content,
                controlsLoaded:
                    action.from > 0
                        ? state.controls.length + action.content.length
                        : action.content.length,
                controlsTotalResults: action.totalElements || 0,
            };

        case controlConstants.CREATING_CONTROL_STARTED:
            return { ...state, isFetchingControl: true };
        case controlConstants.CREATING_CONTROL_FAILURE:
            return { ...state, isFetchingControl: false, control: null, error: action.message || "" };
        case controlConstants.CREATING_CONTROL_SUCCESS:
            return {
                ...state,
                isFetchingControl: false,
                control: action.control,
                controls: [action.control, ...state.controls],
            };

        case controlConstants.UPDATING_CONTROL_STARTED:
            return { ...state, isFetchingControl: true };
        case controlConstants.UPDATING_CONTROL_FAILURE:
            return { ...state, isFetchingControl: false, control: null, error: action.message || "" };
        case controlConstants.UPDATING_CONTROL_SUCCESS:
            return {
                ...state,
                isFetchingControl: false,
                control: action.control,
                controls: [...state.controls].map((control) => {
                    if (action.control.id === control.id) {
                        return action.control;
                    }
                    return control;
                }),
            };

        case controlConstants.DELETE_CONTROL_STARTED:
            return { ...state, isFetchingControl: true };
        case controlConstants.DELETE_CONTROL_FAILURE:
            return { ...state, isFetchingControl: false, control: null, message: action.message || "" };
        case controlConstants.DELETE_CONTROL_SUCCESS:
            return {
                ...state,
                isFetchingControl: false,
                control: action.control,
                controls: [...state.controls].filter((control) => action.control.id !== control.id),
            };
        default:
            return state;
    }
}    
    

import { vehicleConstants } from "@constants/vehicle.constants";

const initialState = {
    isFetchingVehicle: false,
    formVehicle: null,
    vehicle: null,

    isFetchingVehicles: false,
    isFetchingVehiclesFrom: 0,
    vehiclesTotalResults: 0,
    vehiclesLoaded: 0,
    vehicles: [],
};

export default function vehicle(state = initialState, action) {
    switch (action.type) {
        case vehicleConstants.VEHICLE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case vehicleConstants.GET_VEHICLE_STARTED:
            return { ...state, isFetchingVehicle: true };
        case vehicleConstants.GET_VEHICLE_FAILURE:
            return { ...state, isFetchingVehicle: false, vehicle: null, error: action.message || "" };
        case vehicleConstants.GET_VEHICLE_SUCCESS:
            return {
                ...state,
                isFetchingVehicle: false,
                vehicle: action.vehicle,
            };
            
        case vehicleConstants.GET_VEHICLES_STARTED:
            return { ...state, isFetchingVehicles: true,  isFetchingVehiclesFrom: action.from || 0 };
        case vehicleConstants.GET_VEHICLES_FAILURE:
            return { ...state, isFetchingVehicles: false, vehicles: [], error: action.message || "" };
        case vehicleConstants.GET_VEHICLES_SUCCESS:
            return {
                ...state,
                isFetchingVehicles: false,
                vehicles: action.from > 0 ? [...state.vehicles, ...action.vehicles] : action.vehicles,
                vehiclesLoaded:
                    action.from > 0
                        ? state.vehicles.length + action.vehicles.length
                        : action.vehicles.length,
                vehiclesTotalResults: action.totalElements || 0,
            };

        case vehicleConstants.SEARCH_VEHICLES_STARTED:
            return { ...state, isFetchingVehicles: true, isFetchingVehiclesFrom: action.from || 0  };
        case vehicleConstants.SEARCH_VEHICLES_FAILURE:
            return { ...state, isFetchingVehicles: false, vehicles: [], error: action.message || "" };
        case vehicleConstants.SEARCH_VEHICLES_SUCCESS:
            return {
                ...state,
                isFetchingVehicles: false,
                vehicles: action.from > 0 ? [...state.vehicles, ...action.vehicles] : action.vehicles,
                vehiclesLoaded:
                    action.from > 0
                        ? state.vehicles.length + action.vehicles.length
                        : action.vehicles.length,
                vehiclesTotalResults: action.totalElements || 0,
            };

        case vehicleConstants.CREATING_VEHICLE_STARTED:
            return { ...state, isFetchingVehicle: true };
        case vehicleConstants.CREATING_VEHICLE_FAILURE:
            return { ...state, isFetchingVehicle: false, vehicle: null, error: action.message || "" };
        case vehicleConstants.CREATING_VEHICLE_SUCCESS:
            return {
                ...state,
                isFetchingVehicle: false,
                vehicle: action.vehicle,
                vehicles: [action.vehicle, ...state.vehicles],
            };

        case vehicleConstants.UPDATING_VEHICLE_STARTED:
            return { ...state, isFetchingVehicle: true };
        case vehicleConstants.UPDATING_VEHICLE_FAILURE:
            return { ...state, isFetchingVehicle: false, vehicle: null, error: action.message || "" };
        case vehicleConstants.UPDATING_VEHICLE_SUCCESS:
            return {
                ...state,
                isFetchingVehicle: false,
                vehicle: action.vehicle,
                vehicles: [...state.vehicles].map((vehicle) => {
                    if (action.vehicle.id === vehicle.id) {
                        return action.vehicle;
                    }
                    return vehicle;
                }),
            };

        case vehicleConstants.DELETE_VEHICLE_STARTED:
            return { ...state, isFetchingVehicle: true };
        case vehicleConstants.DELETE_VEHICLE_FAILURE:
            return { ...state, isFetchingVehicle: false, vehicle: null, message: action.message || "" };
        case vehicleConstants.DELETE_VEHICLE_SUCCESS:
            return {
                ...state,
                isFetchingVehicle: false,
                vehicle: action.vehicle,
                vehicles: [...state.vehicles].filter((vehicle) => action.vehicle.id !== vehicle.id),
            };
        default:
            return state;
    }
}    
    
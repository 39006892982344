import api from '../api';

export const get = () => {
    return api.get(`/settings`);
};

export const update = (settings) => {
    return api.post(`/update-settings`, settings);
};

export const updateUser = (user) => {
    return api.post(`/update-user`, user);
};

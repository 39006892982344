import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { isMobile } from 'react-device-detect';

import HeaderNotificationView from './HeaderNotificationView';
import HeaderAccount from './HeaderAccount';
import NotificationsContainer from '../../containers/notification/NotificationsContainer';

const initialState = {
    notificationBox: false,
    accountBox: false,
};

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    hasParentNode(element, classNames) {
        let parentNode = element.parentNode;
        if (parentNode) {
            if (typeof parentNode.className === 'string' || parentNode.className instanceof String) {
                if (parentNode.className.includes(classNames)) {
                    return true;
                }
            }
            return this.hasParentNode(parentNode, classNames);
        }
        return false;
    }

    handleDocumentClick = (e) => {
        const { readNotifications } = this.props;

        const target = e.target;
        const { notificationBox, messagesBox, accountBox } = this.state;
        if (notificationBox || messagesBox || accountBox) {
            if (!this.hasParentNode(target, 'menu')) {
                this.setState(initialState);
                if (notificationBox) readNotifications();
            }
        }
    };

    componentDidMount() {
        const { isAuthenticated, notifications, chats, getChats, getNotifications } = this.props;

        document.addEventListener('click', this.handleDocumentClick);

        if (isAuthenticated && notifications.length < 1) getNotifications();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    render() {
        const {
            t,
            match,
            history,
            isAuthenticated,
            user,
            addToStack,
            popStack,
            notificationsUnread,
            messagesUnread,
            getChats,
            getNotifications,
            readNotifications,
            noHeader,
        } = this.props;

        const { notificationBox, messagesBox, accountBox } = this.state;

        if (isAuthenticated) {
            return (
                <div className={`header ${accountBox ? 'show' : ''}`}>
                    <div className="container flex-container no-wrap justify-between" id="computer_header">
                        <div className="flex-container">
                            <Link to="/">
                                <img className="logo" src={'/images/dontrucking.png'} alt={`Don Trucking logo`} />
                            </Link>
                        </div>
                        <div className="flex-container justify-end">
                            <div className="menu">
                                <div
                                    className="icon"
                                    onClick={(e) => {
                                        history.push('/dashboard');
                                        popStack();
                                    }}
                                >
                                    <span>
                                        <ReactSVG src="/icons/home.svg" />
                                    </span>
                                </div>
                                <div
                                    className={`icon notifications ${notificationBox ? 'active' : ''} ${
                                        notificationsUnread > 0 ? 'new' : ''
                                    }`}
                                    data-content={notificationsUnread}
                                    onClick={() => {
                                        if (!notificationBox) getNotifications();
                                        if (notificationBox) readNotifications();

                                        this.setState({
                                            notificationBox: !notificationBox,
                                            accountBox: false,
                                            messagesBox: false,
                                        });
                                    }}
                                >
                                    <span>
                                        <ReactSVG src="/icons/notification.svg" />
                                    </span>
                                    <div className={'box ' + (notificationBox ? 'show' : '')}>
                                        <div className="n_header">
                                            <span>{t('notifications.header')}</span>
                                            <div className="actions">
                                                <Link
                                                    to={'/'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        addToStack({
                                                            name: t('notifications.header'),
                                                            component: (
                                                                <NotificationsContainer
                                                                    onNavigateTo={(url) => {
                                                                        popStack();
                                                                        history.push(url);
                                                                    }}
                                                                />
                                                            ),
                                                        });
                                                        this.setState({
                                                            ...this.state,
                                                            ...initialState,
                                                        });
                                                    }}
                                                >
                                                    {t('details.header')}
                                                </Link>
                                            </div>
                                        </div>
                                        <HeaderNotificationView
                                            {...this.props}
                                            goToPage={(e) => {
                                                e.preventDefault();
                                                addToStack({
                                                    name: t('notifications.header'),
                                                    component: (
                                                        <NotificationsContainer
                                                            onNavigateTo={(url) => {
                                                                popStack();
                                                                history.push(url);
                                                            }}
                                                        />
                                                    ),
                                                });
                                                this.setState({ ...this.state, ...initialState });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="listbutton"
                                    onClick={() => {
                                        this.setState({
                                            accountBox: !accountBox,
                                            notificationBox: false,
                                            messagesBox: false,
                                        });
                                    }}
                                >
                                    <ReactSVG src="/icons/menu.svg" />
                                </div>
                                <nav className={accountBox ? 'show' : ''}>
                                    <div className="content-box scrollable">
                                        <div
                                            className="closebutton"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    accountBox: !accountBox,
                                                    notificationBox: false,
                                                    messagesBox: false,
                                                });
                                            }}
                                        />
                                        <HeaderAccount {...this.props} />
                                        <ul>
                                            <Link to="/brands">
                                                <li>{t('menu.brands')}</li>
                                            </Link>
                                            <Link to="/companies">
                                                <li>{t('menu.companies')}</li>
                                            </Link>
                                            <Link to="/users">
                                                <li>{t('menu.users')}</li>
                                            </Link>
                                            <hr />
                                            {/* <Link to="/settings">
                                                <li>{t('menu.settings')}</li>
                                            </Link> */}
                                            <Link to="/logout">
                                                <li id="logout">{t('menu.logout')}</li>
                                            </Link>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-menu">
                        <Link to="/" className={'section' + (match.path === '/dashboard' ? ' active' : '')}>
                            <ReactSVG src="/icons/home.svg" />
                        </Link>
                        <Link
                            to="/notifications"
                            className={'section' + (match.path === '/notifications' ? ' active' : '')}
                            data-content={notificationsUnread}
                        >
                            <ReactSVG src="/icons/notification.svg" />
                        </Link>
                    </div>
                </div>
            );
        } else if (!noHeader) {
            return (
                <div className={`header clear ${beta}`}>
                    <Link to="/">
                        <img alt="logo" className="middle" src={'/images/dontrucking.png'} />
                    </Link>
                </div>
            );
        }

        return null;
    }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateBusinessContainer from '@containers/business/crud/CreateBusinessContainer';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformBusinessToValue = (business) => ({
    value: business.id,
    label: business.name,
    id: business.id,
    business: business,
});

class BusinessInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            business: this.props.business ? transformBusinessToValue(this.props.business) : null,
            searchParameters: this.props.searchParameters ? this.props.searchParameters : {}
        };
    }

    static defaultProps = {
        isDisabled: false
    }

    onChange = (newBusiness) => {
        this.setState({
            business: newBusiness,
        });
        const { onChange } = this.props;
        onChange && onChange(newBusiness ? newBusiness.business : null);
    };

    render() {
        const { t, placeholder, isFetchingBusinesss, searchBusinesses, addToStack, isDisabled } = this.props;
        const { business, searchParameters } = this.state;
        return (
            <div className="input-group no-margin-top">
                <AsyncCreatableSelect
                    isMulti={false}
                    isClearable
                    cacheOptions
                    placeholder={placeholder ? placeholder : `${t('form.label.business')}`}
                    noOptionsMessage={() => t('form.startTyping')}
                    formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
                    isLoading={isFetchingBusinesss}
                    isDisabled={isDisabled || isFetchingBusinesss}
                    loadOptions={(inputValue, callback) => {
                        if (searchTimeout) clearTimeout(searchTimeout);
                        searchTimeout = setTimeout(() => {
                            searchBusinesses({ query: inputValue, ...searchParameters }, 0, 40).then((response) => {
                                callback(response.map((business) => transformBusinessToValue(business)));
                            });
                        }, 800);
                    }}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                        this.onChange(e);
                    }}
                    onCreateOption={(inputValue) => {
                        addToStack({
                            name: t('business.new'),
                            component: (
                                <CreateBusinessContainer
                                    targetName={inputValue}
                                    callback={(newBusiness) => {
                                        this.onChange(transformBusinessToValue(newBusiness));
                                    }}
                                />
                            ),
                        });
                    }}
                    value={business}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth.user,
        ...state.business,
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (name, value) => dispatch(businessActions.changeValue(name, value)),
        searchBusinesses: (searchParameters, from, amount) =>
            dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),

        popStack: () => dispatch(navigationActions.popStack()),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(BusinessInput));

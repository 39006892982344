
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateBusinessPage from "@components/business/crud/CreateBusinessPage.js";

import * as businessActions from "@actions/businessActions";
import * as navigationActions from "@actions/navigationActions";

class CreateBusinessContainer extends PureComponent {
    render() {
        const {  changeValue, createBusiness, popStack, callback, ...props } = this.props;
        return (
            <CreateBusinessPage
                {...props}
                onChange={changeValue}
                createBusiness={(business) =>
                    createBusiness( business).then((response) => {
                        popStack && popStack();
                        callback && callback(response.business)
                    })
                }
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth.user,
        ...state.business,
        ...ownProps
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createBusiness: ( business) => dispatch(businessActions.createBusiness( business)),
        changeValue: (name, value) => dispatch(businessActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateBusinessContainer));
    
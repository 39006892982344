
import { accidentConstants } from "@constants/accident.constants";

const initialState = {
    isFetchingAccident: false,
    formAccident: null,
    accident: null,

    isFetchingAccidents: false,
    isFetchingAccidentsFrom: 0,
    accidentsTotalResults: 0,
    accidentsLoaded: 0,
    accidents: [],
};

export default function accident(state = initialState, action) {
    switch (action.type) {
        case accidentConstants.ACCIDENT_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case accidentConstants.GET_ACCIDENT_STARTED:
            return { ...state, isFetchingAccident: true };
        case accidentConstants.GET_ACCIDENT_FAILURE:
            return { ...state, isFetchingAccident: false, accident: null, error: action.message || "" };
        case accidentConstants.GET_ACCIDENT_SUCCESS:
            return {
                ...state,
                isFetchingAccident: false,
                accident: action.accident,
            };
            
        case accidentConstants.GET_ACCIDENTS_STARTED:
            return { ...state, isFetchingAccidents: true,  isFetchingAccidentsFrom: action.from || 0 };
        case accidentConstants.GET_ACCIDENTS_FAILURE:
            return { ...state, isFetchingAccidents: false, accidents: [], error: action.message || "" };
        case accidentConstants.GET_ACCIDENTS_SUCCESS:
            return {
                ...state,
                isFetchingAccidents: false,
                accidents: action.from > 0 ? [...state.accidents, ...action.content] : action.content,
                accidentsLoaded:
                    action.from > 0
                        ? state.accidents.length + action.content.length
                        : action.content.length,
                accidentsTotalResults: action.totalElements || 0,
            };

        case accidentConstants.SEARCH_ACCIDENTS_STARTED:
            return { ...state, isFetchingAccidents: true, isFetchingAccidentsFrom: action.from || 0  };
        case accidentConstants.SEARCH_ACCIDENTS_FAILURE:
            return { ...state, isFetchingAccidents: false, accidents: [], error: action.message || "" };
        case accidentConstants.SEARCH_ACCIDENTS_SUCCESS:
            return {
                ...state,
                isFetchingAccidents: false,
                accidents: action.from > 0 ? [...state.accidents, ...action.content] : action.content,
                accidentsLoaded:
                    action.from > 0
                        ? state.accidents.length + action.content.length
                        : action.content.length,
                accidentsTotalResults: action.totalElements || 0,
            };

        case accidentConstants.CREATING_ACCIDENT_STARTED:
            return { ...state, isFetchingAccident: true };
        case accidentConstants.CREATING_ACCIDENT_FAILURE:
            return { ...state, isFetchingAccident: false, accident: null, error: action.message || "" };
        case accidentConstants.CREATING_ACCIDENT_SUCCESS:
            return {
                ...state,
                isFetchingAccident: false,
                accident: action.accident,
                accidents: [action.accident, ...state.accidents],
            };

        case accidentConstants.UPDATING_ACCIDENT_STARTED:
            return { ...state, isFetchingAccident: true };
        case accidentConstants.UPDATING_ACCIDENT_FAILURE:
            return { ...state, isFetchingAccident: false, accident: null, error: action.message || "" };
        case accidentConstants.UPDATING_ACCIDENT_SUCCESS:
            return {
                ...state,
                isFetchingAccident: false,
                accident: action.accident,
                accidents: [...state.accidents].map((accident) => {
                    if (action.accident.id === accident.id) {
                        return action.accident;
                    }
                    return accident;
                }),
            };

        case accidentConstants.DELETE_ACCIDENT_STARTED:
            return { ...state, isFetchingAccident: true };
        case accidentConstants.DELETE_ACCIDENT_FAILURE:
            return { ...state, isFetchingAccident: false, accident: null, message: action.message || "" };
        case accidentConstants.DELETE_ACCIDENT_SUCCESS:
            return {
                ...state,
                isFetchingAccident: false,
                accident: action.accident,
                accidents: [...state.accidents].filter((accident) => action.accident.id !== accident.id),
            };
        default:
            return state;
    }
}    
    
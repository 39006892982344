
import { banConstants } from "@constants/ban.constants";

const initialState = {
    isFetchingBan: false,
    formBan: null,
    ban: null,

    isFetchingBans: false,
    isFetchingBansFrom: 0,
    bansTotalResults: 0,
    bansLoaded: 0,
    bans: [],
};

export default function ban(state = initialState, action) {
    switch (action.type) {
        case banConstants.BAN_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case banConstants.GET_BAN_STARTED:
            return { ...state, isFetchingBan: true };
        case banConstants.GET_BAN_FAILURE:
            return { ...state, isFetchingBan: false, ban: null, error: action.message || "" };
        case banConstants.GET_BAN_SUCCESS:
            return {
                ...state,
                isFetchingBan: false,
                ban: action.ban,
            };
            
        case banConstants.GET_BANS_STARTED:
            return { ...state, isFetchingBans: true,  isFetchingBansFrom: action.from || 0 };
        case banConstants.GET_BANS_FAILURE:
            return { ...state, isFetchingBans: false, bans: [], error: action.message || "" };
        case banConstants.GET_BANS_SUCCESS:
            return {
                ...state,
                isFetchingBans: false,
                bans: action.from > 0 ? [...state.bans, ...action.content] : action.content,
                bansLoaded:
                    action.from > 0
                        ? state.bans.length + action.content.length
                        : action.content.length,
                bansTotalResults: action.totalElements || 0,
            };

        case banConstants.SEARCH_BANS_STARTED:
            return { ...state, isFetchingBans: true, isFetchingBansFrom: action.from || 0  };
        case banConstants.SEARCH_BANS_FAILURE:
            return { ...state, isFetchingBans: false, bans: [], error: action.message || "" };
        case banConstants.SEARCH_BANS_SUCCESS:
            return {
                ...state,
                isFetchingBans: false,
                bans: action.from > 0 ? [...state.bans, ...action.content] : action.content,
                bansLoaded:
                    action.from > 0
                        ? state.bans.length + action.content.length
                        : action.content.length,
                bansTotalResults: action.totalElements || 0,
            };

        case banConstants.CREATING_BAN_STARTED:
            return { ...state, isFetchingBan: true };
        case banConstants.CREATING_BAN_FAILURE:
            return { ...state, isFetchingBan: false, ban: null, error: action.message || "" };
        case banConstants.CREATING_BAN_SUCCESS:
            return {
                ...state,
                isFetchingBan: false,
                ban: action.ban,
                bans: [action.ban, ...state.bans],
            };

        case banConstants.UPDATING_BAN_STARTED:
            return { ...state, isFetchingBan: true };
        case banConstants.UPDATING_BAN_FAILURE:
            return { ...state, isFetchingBan: false, ban: null, error: action.message || "" };
        case banConstants.UPDATING_BAN_SUCCESS:
            return {
                ...state,
                isFetchingBan: false,
                ban: action.ban,
                bans: [...state.bans].map((ban) => {
                    if (action.ban.id === ban.id) {
                        return action.ban;
                    }
                    return ban;
                }),
            };

        case banConstants.DELETE_BAN_STARTED:
            return { ...state, isFetchingBan: true };
        case banConstants.DELETE_BAN_FAILURE:
            return { ...state, isFetchingBan: false, ban: null, message: action.message || "" };
        case banConstants.DELETE_BAN_SUCCESS:
            return {
                ...state,
                isFetchingBan: false,
                ban: action.ban,
                bans: [...state.bans].filter((ban) => action.ban.id !== ban.id),
            };
        default:
            return state;
    }
}    
    

import { breakdownConstants } from "@constants/breakdown.constants";

const initialState = {
    isFetchingBreakdown: false,
    formBreakdown: null,
    breakdown: null,

    isFetchingBreakdowns: false,
    isFetchingBreakdownsFrom: 0,
    breakdownsTotalResults: 0,
    breakdownsLoaded: 0,
    breakdowns: [],
};

export default function breakdown(state = initialState, action) {
    switch (action.type) {
        case breakdownConstants.BREAKDOWN_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case breakdownConstants.GET_BREAKDOWN_STARTED:
            return { ...state, isFetchingBreakdown: true };
        case breakdownConstants.GET_BREAKDOWN_FAILURE:
            return { ...state, isFetchingBreakdown: false, breakdown: null, error: action.message || "" };
        case breakdownConstants.GET_BREAKDOWN_SUCCESS:
            return {
                ...state,
                isFetchingBreakdown: false,
                breakdown: action.breakdown,
            };
            
        case breakdownConstants.GET_BREAKDOWNS_STARTED:
            return { ...state, isFetchingBreakdowns: true,  isFetchingBreakdownsFrom: action.from || 0 };
        case breakdownConstants.GET_BREAKDOWNS_FAILURE:
            return { ...state, isFetchingBreakdowns: false, breakdowns: [], error: action.message || "" };
        case breakdownConstants.GET_BREAKDOWNS_SUCCESS:
            return {
                ...state,
                isFetchingBreakdowns: false,
                breakdowns: action.from > 0 ? [...state.breakdowns, ...action.content] : action.content,
                breakdownsLoaded:
                    action.from > 0
                        ? state.breakdowns.length + action.content.length
                        : action.content.length,
                breakdownsTotalResults: action.totalElements || 0,
            };

        case breakdownConstants.SEARCH_BREAKDOWNS_STARTED:
            return { ...state, isFetchingBreakdowns: true, isFetchingBreakdownsFrom: action.from || 0  };
        case breakdownConstants.SEARCH_BREAKDOWNS_FAILURE:
            return { ...state, isFetchingBreakdowns: false, breakdowns: [], error: action.message || "" };
        case breakdownConstants.SEARCH_BREAKDOWNS_SUCCESS:
            return {
                ...state,
                isFetchingBreakdowns: false,
                breakdowns: action.from > 0 ? [...state.breakdowns, ...action.content] : action.content,
                breakdownsLoaded:
                    action.from > 0
                        ? state.breakdowns.length + action.content.length
                        : action.content.length,
                breakdownsTotalResults: action.totalElements || 0,
            };

        case breakdownConstants.CREATING_BREAKDOWN_STARTED:
            return { ...state, isFetchingBreakdown: true };
        case breakdownConstants.CREATING_BREAKDOWN_FAILURE:
            return { ...state, isFetchingBreakdown: false, breakdown: null, error: action.message || "" };
        case breakdownConstants.CREATING_BREAKDOWN_SUCCESS:
            return {
                ...state,
                isFetchingBreakdown: false,
                breakdown: action.breakdown,
                breakdowns: [action.breakdown, ...state.breakdowns],
            };

        case breakdownConstants.UPDATING_BREAKDOWN_STARTED:
            return { ...state, isFetchingBreakdown: true };
        case breakdownConstants.UPDATING_BREAKDOWN_FAILURE:
            return { ...state, isFetchingBreakdown: false, breakdown: null, error: action.message || "" };
        case breakdownConstants.UPDATING_BREAKDOWN_SUCCESS:
            return {
                ...state,
                isFetchingBreakdown: false,
                breakdown: action.breakdown,
                breakdowns: [...state.breakdowns].map((breakdown) => {
                    if (action.breakdown.id === breakdown.id) {
                        return action.breakdown;
                    }
                    return breakdown;
                }),
            };

        case breakdownConstants.DELETE_BREAKDOWN_STARTED:
            return { ...state, isFetchingBreakdown: true };
        case breakdownConstants.DELETE_BREAKDOWN_FAILURE:
            return { ...state, isFetchingBreakdown: false, breakdown: null, message: action.message || "" };
        case breakdownConstants.DELETE_BREAKDOWN_SUCCESS:
            return {
                ...state,
                isFetchingBreakdown: false,
                breakdown: action.breakdown,
                breakdowns: [...state.breakdowns].filter((breakdown) => action.breakdown.id !== breakdown.id),
            };
        default:
            return state;
    }
}    
    
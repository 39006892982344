
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateBrandPage from "@components/brand/crud/UpdateBrandPage.js";

import * as brandActions from "@actions/brandActions";
import * as navigationActions from "@actions/navigationActions";

class UpdateBrandContainer extends PureComponent {
    render() {
        const {  changeValue, updateBrand, popStack, callback, ...props } = this.props;
        return (
            <UpdateBrandPage
                {...props}
                onChange={changeValue}
                updateBrand={(brand) =>
                    updateBrand( brand).then((response) => {
                        popStack && popStack();
                        callback && callback(response.brand)
                    })
                }
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth.user,
        ...state.brand,
        ownProps
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBrand: ( brand) => dispatch(brandActions.updateBrand( brand)),
        changeValue: (name, value) => dispatch(brandActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateBrandContainer));
    

import React, { Component } from "react";

import Loader from "@uicomponents/Loader";
import AsideView from "@uicomponents/AsideView";

import BusinessForm from "./BusinessForm";

export default class UpdateBusinessPage extends Component {
    
    componentDidMount() {
        const { onChange, ownProps } = this.props;
        onChange("formBusiness", ownProps.business);
    }

    render() {
        const { t, formBusiness, isFetchingBusiness, updateBusiness } = this.props;

        if (!formBusiness || isFetchingBusiness) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("business.update")}</h1>
                <BusinessForm
                    {...this.props}
                    key={formBusiness.id}
                    onSubmit={(business) => updateBusiness(business)}
                />
            </AsideView>
        );
    }
}
    
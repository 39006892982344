
import { businessConstants } from '../constants';
import * as businessApi from '@api/businessApi';

export const changeValue = (name, value) => ({
    type: businessConstants.BUSINESS_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching business
 */
export const startGetBusiness = () => ({
    type: businessConstants.GET_BUSINESS_STARTED,
});

export const getBusinessSuccess = (result) => ({
    type: businessConstants.GET_BUSINESS_SUCCESS,
    business: result,
});

export const getBusinessFailure = (error) => ({
    type: businessConstants.GET_BUSINESS_FAILURE,
    ...error,
});

export const getBusiness = (id) => {
    return (dispatch) => {
        dispatch(startGetBusiness());

        const promise = businessApi.get(id);

        promise
            .then((result) => {
                dispatch(getBusinessSuccess(result));
            })
            .catch((error) => {
                dispatch(getBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Creating business
 */
export const changeCreateBusiness = (name, value) => ({
    type: businessConstants.CREATING_BUSINESS_CHANGE_VALUE,
    name,
    value,
});
export const startCreateBusiness = () => ({
    type: businessConstants.CREATING_BUSINESS_STARTED,
});

export const createBusinessSuccess = (result, business) => ({
    type: businessConstants.CREATING_BUSINESS_SUCCESS,
    business,
    ...result,
});

export const createBusinessFailure = (error) => ({
    type: businessConstants.CREATING_BUSINESS_FAILURE,
    ...error,
});

export const createBusiness = (business) => {
    return (dispatch) => {
        dispatch(startCreateBusiness());

        const promise = businessApi.create(business);

        promise
            .then((result) => {
                dispatch(createBusinessSuccess(result, business));
            })
            .catch((error) => {
                dispatch(createBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Updating business
 */
export const changeUpdateBusiness = (name, value) => ({
    type: businessConstants.UPDATING_BUSINESS_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateBusiness = () => ({
    type: businessConstants.UPDATING_BUSINESS_STARTED,
});

export const updateBusinessSuccess = (result, business) => ({
    type: businessConstants.UPDATING_BUSINESS_SUCCESS,
    business,
    ...result,
});

export const updateBusinessFailure = (error) => ({
    type: businessConstants.UPDATING_BUSINESS_FAILURE,
    ...error,
});

export const updateBusiness = (business) => {
    return (dispatch) => {
        dispatch(startUpdateBusiness());

        const promise = businessApi.update(business);

        promise
            .then((result) => {
                dispatch(updateBusinessSuccess(result, business));
            })
            .catch((error) => {
                dispatch(updateBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Deleting business
 */
export const startDeleteBusiness = () => ({
    type: businessConstants.DELETE_BUSINESS_STARTED,
});

export const deleteBusinessSuccess = (result, business) => ({
    type: businessConstants.DELETE_BUSINESS_SUCCESS,
    business,
    ...result,
});

export const deleteBusinessFailure = (error) => ({
    type: businessConstants.DELETE_BUSINESS_FAILURE,
    ...error,
});
export const deleteBusiness = (business) => {
    return (dispatch) => {
        dispatch(startDeleteBusiness());

        const promise = businessApi.remove(business);

        promise
            .then((result) => {
                dispatch(deleteBusinessSuccess(result, business));
            })
            .catch((error) => {
                dispatch(deleteBusinessFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching businesses
 */
export const startGetBusinesses = (from) => ({
    type: businessConstants.GET_BUSINESSES_STARTED,
    from: from,
});

export const getBusinessesSuccess = (result, from) => ({
    type: businessConstants.GET_BUSINESSES_SUCCESS,
    from: from,
    businesses: result,
});

export const getBusinessesFailure = (error) => ({
    type: businessConstants.GET_BUSINESSES_FAILURE,
    ...error,
});

export const getBusinesses = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetBusinesses(from));

        const promise = businessApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getBusinessesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getBusinessesFailure(error));
            });

        return promise;
    };
};

export const searchBusinesses = (searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetBusinesses(from));

        const promise = businessApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getBusinessesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getBusinessesFailure(error));
            });

        return promise;
    };
};
    

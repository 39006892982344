import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import BusinessInput from '@components/business/crud/BusinessInput';

import { activateInputs } from '@utils/formUtils';

export default class BrandForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formBrand } = this.props;

        onSubmit && onSubmit(formBrand);
    }

    render() {
        const { onChange, t, formBrand } = this.props;

        return (
            <>
                <div className="step-form " key={`${formBrand.id}${formBrand.nonce}`}>
                    <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="input-container">
                            <div className="input-group">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        value={formBrand.name}
                                        onChange={(event) => {
                                            const newBrand = { ...formBrand };
                                            newBrand.name = event.target.value;

                                            onChange('formBrand', newBrand, event);
                                        }}
                                    />
                                    <label>{t('form.label.name')}*</label>
                                </div>
                                {this.validator.message(t('form.label.name'), formBrand.name, 'required')}
                            </div>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <BusinessInput
                                        business={formBrand.business}
                                        placeholder={`${t('form.label.business')}*`}
                                        onChange={(newBusiness) => {
                                            const newBrand = { ...formBrand };
                                            newBrand.business = newBusiness;

                                            onChange('formBrand', newItem, event);
                                        }}
                                    />
                                </div>
                                {this.validator.message(t('form.label.business'), formBrand.business, 'required')}
                            </div>
                            <div className="input-group">
                                <div className="input-group">
                                    <textarea
                                        value={formBrand.description}
                                        onChange={(event) => {
                                            const newBrand = { ...formBrand };
                                            newBrand.description = event.target.value;

                                            onChange('formBrand', newBrand, event);
                                        }}
                                    ></textarea>
                                    <label>{t('form.label.description')}*</label>
                                </div>
                                {this.validator.message(t('form.label.description'), formBrand.description, 'required')}
                            </div>
                            <div className="input-group">
                                <div className="input-group no-margin-top">
                                    <input
                                        type="text"
                                        value={formBrand.url}
                                        onChange={(event) => {
                                            const newBrand = { ...formBrand };
                                            newBrand.url = event.target.value;

                                            onChange('formBrand', newBrand, event);
                                        }}
                                    />
                                    <label>{t('form.label.url')}*</label>
                                </div>
                                {this.validator.message(t('form.label.url'), formBrand.url, 'required')}
                            </div>
                            <div className="input-group more right">
                                <input type="submit" disabled={!this.validator.allValid()} value={t('form.save')} />
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

import React, { Component, Suspense, lazy } from 'react';

const VehiclesContainer = lazy(() => import('@containers/vehicle/VehiclesContainer'));
const ControlsContainer = lazy(() => import('@containers/control/ControlsContainer'));
const TiresContainer = lazy(() => import('@containers/tire/TiresContainer'));
const InsurancesContainer = lazy(() => import('@containers/insurance/InsurancesContainer'));
const BreakdownsContainer = lazy(() => import('@containers/breakdown/BreakdownsContainer'));
const AccidentsContainer = lazy(() => import('@containers/accident/AccidentsContainer'));
const MaintenanceContainer = lazy(() => import('@containers/maintenance/MaintenanceContainer'));
const RemindersContainer = lazy(() => import('@containers/reminder/RemindersContainer'));
const MotsContainer = lazy(() => import('@containers/mot/MotsContainer'));

const DriversContainer = lazy(() => import('@containers/driver/DriversContainer'));
const ComplaintsContainer = lazy(() => import('@containers/complaint/ComplaintsContainer'));
const CertificatesContainer = lazy(() => import('@containers/certificate/CertificatesContainer'));
const BansContainer = lazy(() => import('@containers/ban/BansContainer'));

const WarehousesContainer = lazy(() => import('@containers/warehouse/WarehousesContainer'));
const ItemsContainer = lazy(() => import('@containers/item/ItemsContainer'));

import Loader from '@uicomponents/Loader';

export default class DashboardPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: this.props.match.params.tab ? this.props.match.params.tab : 'vehicles',
            subTab: null,
            selectedBaseElement: null,
        };
    }

    componentDidMount() {
        const { match } = this.props;

        if (match.params.tab) {
            switch (match.params.tab) {
                case 'vehicles':
                default:
                    this.setState({ tab: 'vehicles' });
                    break;
                case 'drivers':
                    this.setState({ tab: 'drivers' });
                    break;
                case 'warehouses':
                    this.setState({ tab: 'warehouses' });
                    break;
            }
        }
    }

    render() {
        const { t, addToStack } = this.props;
        const { tab, subTab, selectedBaseElement } = this.state;

        console.log(selectedBaseElement);
        return (
            <div className="container">
                <div className="dashboard-section">
                    <div className="dashboard-main" style={selectedBaseElement ? { width: '25%' } : {}}>
                        <div className="tabs">
                            <ul>
                                {(selectedBaseElement == null || tab == 'vehicles') && (
                                    <li
                                        className={tab == 'vehicles' ? 'active' : ''}
                                        onClick={() =>
                                            this.setState({
                                                tab: 'vehicles',
                                                selectedBaseElement: null,
                                            })
                                        }
                                    >
                                        {t('dashboard.main.vehicles')}
                                    </li>
                                )}
                                {(selectedBaseElement == null || tab == 'drivers') && (
                                    <li
                                        className={tab == 'drivers' ? 'active' : ''}
                                        onClick={() =>
                                            this.setState({
                                                tab: 'drivers',
                                                selectedBaseElement: null,
                                            })
                                        }
                                    >
                                        {t('dashboard.main.drivers')}
                                    </li>
                                )}

                                {(selectedBaseElement == null || tab == 'warehouses') && (
                                    <li
                                        className={tab == 'warehouses' ? 'active' : ''}
                                        onClick={() =>
                                            this.setState({
                                                tab: 'warehouses',
                                                selectedBaseElement: null,
                                            })
                                        }
                                    >
                                        {t('dashboard.main.warehouses')}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <Suspense fallback={<Loader />}>
                            <section className={`activity${tab === 'vehicles' ? ' active' : ''}`}>
                                {tab === 'vehicles' ? (
                                    <VehiclesContainer
                                        key={selectedBaseElement?.id}
                                        small={selectedBaseElement ? true : false}
                                        selected={[selectedBaseElement?.id]}
                                        onSelect={(vehicle) =>
                                            this.setState({ selectedBaseElement: vehicle, subTab: 'controls' })
                                        }
                                    />
                                ) : null}
                            </section>
                            <section className={`activity${tab === 'drivers' ? ' active' : ''}`}>
                                {tab === 'drivers' ? (
                                    <DriversContainer
                                        key={selectedBaseElement?.id}
                                        small={selectedBaseElement ? true : false}
                                        selected={[selectedBaseElement?.id]}
                                        onSelect={(driver) =>
                                            this.setState({ selectedBaseElement: driver, subTab: 'certificates' })
                                        }
                                    />
                                ) : null}
                            </section>
                            <section className={`activity${tab === 'warehouses' ? ' active' : ''}`}>
                                {tab === 'warehouses' ? (
                                    <WarehousesContainer
                                        key={selectedBaseElement?.id}
                                        small={selectedBaseElement ? true : false}
                                        selected={[selectedBaseElement?.id]}
                                        onSelect={(warehouse) =>
                                            this.setState({ selectedBaseElement: warehouse, subTab: 'items' })
                                        }
                                    />
                                ) : null}
                            </section>
                        </Suspense>
                    </div>
                    {selectedBaseElement && (
                        <div className="dashboard-detail">
                            <h1>
                                {tab === 'vehicles'
                                    ? selectedBaseElement.licensePlate
                                    : tab === 'drivers'
                                    ? `${selectedBaseElement.firstName} ${selectedBaseElement.lastName}`
                                    : selectedBaseElement.name}
                            </h1>
                            <div className="tabs">
                                <ul>
                                    {tab == 'vehicles' && (
                                        <>
                                            <li
                                                className={subTab == 'controls' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'controls' })}
                                            >
                                                {t('dashboard.main.controls')}
                                            </li>
                                            <li
                                                className={subTab == 'reminders' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'reminders' })}
                                            >
                                                {t('dashboard.main.reminders')}
                                            </li>
                                            <li
                                                className={subTab == 'breakdowns' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'breakdowns' })}
                                            >
                                                {t('dashboard.main.breakdowns')}
                                            </li>
                                            <li
                                                className={subTab == 'accidents' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'accidents' })}
                                            >
                                                {t('dashboard.main.accidents')}
                                            </li>
                                            <li
                                                className={subTab == 'tires' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'tires' })}
                                            >
                                                {t('dashboard.main.tires')}
                                            </li>
                                            <li
                                                className={subTab == 'mots' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'mots' })}
                                            >
                                                {t('dashboard.main.mots')}
                                            </li>
                                            <li
                                                className={subTab == 'insurances' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'insurances' })}
                                            >
                                                {t('dashboard.main.insurances')}
                                            </li>
                                        </>
                                    )}
                                    {tab == 'drivers' && (
                                        <>
                                            <li
                                                className={subTab == 'certificates' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'certificates' })}
                                            >
                                                {t('dashboard.main.certificates')}
                                            </li>
                                            <li
                                                className={subTab == 'complaints' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'complaints' })}
                                            >
                                                {t('dashboard.main.complaints')}
                                            </li>
                                            <li
                                                className={subTab == 'accidents' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'accidents' })}
                                            >
                                                {t('dashboard.main.accidents')}
                                            </li>
                                            <li
                                                className={subTab == 'bans' ? 'active' : ''}
                                                onClick={() => this.setState({ subTab: 'bans' })}
                                            >
                                                {t('dashboard.main.bans')}
                                            </li>
                                        </>
                                    )}

                                    {tab == 'warehouses' && (
                                        <li
                                            className={subTab == 'items' ? 'active' : ''}
                                            onClick={() => this.setState({ subTab: 'items' })}
                                        >
                                            {t('dashboard.main.items')}
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <Suspense fallback={<Loader />}>
                                <section className={`activity${subTab === 'controls' ? ' active' : ''}`}>
                                    {subTab === 'controls' ? (
                                        <ControlsContainer
                                            key={selectedBaseElement.id}
                                            vehicle={selectedBaseElement}
                                            vehicleId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'reminders' ? ' active' : ''}`}>
                                    {subTab === 'reminders' ? (
                                        <RemindersContainer
                                            key={selectedBaseElement.id}
                                            vehicleId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'breakdowns' ? ' active' : ''}`}>
                                    {subTab === 'breakdowns' ? (
                                        <BreakdownsContainer
                                            key={selectedBaseElement.id}
                                            vehicle={selectedBaseElement}
                                            vehicleId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'accidents' ? ' active' : ''}`}>
                                    {subTab === 'accidents' ? (
                                        tab === 'vehicles' ? (
                                            <AccidentsContainer
                                                key={selectedBaseElement.id}
                                                vehicle={selectedBaseElement}
                                                vehicleId={selectedBaseElement.id}
                                            />
                                        ) : (
                                            <AccidentsContainer
                                                key={selectedBaseElement.id}
                                                driver={selectedBaseElement}
                                                driverId={selectedBaseElement.id}
                                            />
                                        )
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'tires' ? ' active' : ''}`}>
                                    {subTab === 'tires' ? (
                                        <TiresContainer
                                            key={selectedBaseElement.id}
                                            vehicle={selectedBaseElement}
                                            vehicleId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'mots' ? ' active' : ''}`}>
                                    {subTab === 'mots' ? (
                                        <MotsContainer
                                            key={selectedBaseElement.id}
                                            vehicle={selectedBaseElement}
                                            vehicleId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'insurances' ? ' active' : ''}`}>
                                    {subTab === 'insurances' ? (
                                        <InsurancesContainer
                                            key={selectedBaseElement.id}
                                            vehicle={selectedBaseElement}
                                            vehicleId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'complaints' ? ' active' : ''}`}>
                                    {subTab === 'complaints' ? (
                                        <ComplaintsContainer
                                            key={selectedBaseElement.id}
                                            driver={selectedBaseElement}
                                            driverId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'certificates' ? ' active' : ''}`}>
                                    {subTab === 'certificates' ? (
                                        <CertificatesContainer
                                            key={selectedBaseElement.id}
                                            driver={selectedBaseElement}
                                            driverId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'bans' ? ' active' : ''}`}>
                                    {subTab === 'bans' ? (
                                        <BansContainer
                                            key={selectedBaseElement.id}
                                            driver={selectedBaseElement}
                                            driverId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                                <section className={`activity${subTab === 'items' ? ' active' : ''}`}>
                                    {subTab === 'items' ? (
                                        <ItemsContainer
                                            key={selectedBaseElement.id}
                                            driver={selectedBaseElement}
                                            driverId={selectedBaseElement.id}
                                        />
                                    ) : null}
                                </section>
                            </Suspense>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}


import { invoiceConstants } from "@constants/invoice.constants";

const initialState = {
    isFetchingInvoice: false,
    formInvoice: null,
    invoice: null,

    isFetchingInvoices: false,
    isFetchingInvoicesFrom: 0,
    invoicesTotalResults: 0,
    invoicesLoaded: 0,
    invoices: [],
};

export default function invoice(state = initialState, action) {
    switch (action.type) {
        case invoiceConstants.INVOICE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case invoiceConstants.GET_INVOICE_STARTED:
            return { ...state, isFetchingInvoice: true };
        case invoiceConstants.GET_INVOICE_FAILURE:
            return { ...state, isFetchingInvoice: false, invoice: null, error: action.message || "" };
        case invoiceConstants.GET_INVOICE_SUCCESS:
            return {
                ...state,
                isFetchingInvoice: false,
                invoice: action.invoice,
            };
            
        case invoiceConstants.GET_INVOICES_STARTED:
            return { ...state, isFetchingInvoices: true,  isFetchingInvoicesFrom: action.from || 0 };
        case invoiceConstants.GET_INVOICES_FAILURE:
            return { ...state, isFetchingInvoices: false, invoices: [], error: action.message || "" };
        case invoiceConstants.GET_INVOICES_SUCCESS:
            return {
                ...state,
                isFetchingInvoices: false,
                invoices: action.from > 0 ? [...state.invoices, ...action.invoices] : action.invoices,
                invoicesLoaded:
                    action.from > 0
                        ? state.invoices.length + action.invoices.length
                        : action.invoices.length,
                invoicesTotalResults: action.totalElements || 0,
            };

        case invoiceConstants.SEARCH_INVOICES_STARTED:
            return { ...state, isFetchingInvoices: true, isFetchingInvoicesFrom: action.from || 0  };
        case invoiceConstants.SEARCH_INVOICES_FAILURE:
            return { ...state, isFetchingInvoices: false, invoices: [], error: action.message || "" };
        case invoiceConstants.SEARCH_INVOICES_SUCCESS:
            return {
                ...state,
                isFetchingInvoices: false,
                invoices: action.from > 0 ? [...state.invoices, ...action.invoices] : action.invoices,
                invoicesLoaded:
                    action.from > 0
                        ? state.invoices.length + action.invoices.length
                        : action.invoices.length,
                invoicesTotalResults: action.totalElements || 0,
            };

        case invoiceConstants.CREATING_INVOICE_STARTED:
            return { ...state, isFetchingInvoice: true };
        case invoiceConstants.CREATING_INVOICE_FAILURE:
            return { ...state, isFetchingInvoice: false, invoice: null, error: action.message || "" };
        case invoiceConstants.CREATING_INVOICE_SUCCESS:
            return {
                ...state,
                isFetchingInvoice: false,
                invoice: action.invoice,
                invoices: [action.invoice, ...state.invoices],
            };

        case invoiceConstants.UPDATING_INVOICE_STARTED:
            return { ...state, isFetchingInvoice: true };
        case invoiceConstants.UPDATING_INVOICE_FAILURE:
            return { ...state, isFetchingInvoice: false, invoice: null, error: action.message || "" };
        case invoiceConstants.UPDATING_INVOICE_SUCCESS:
            return {
                ...state,
                isFetchingInvoice: false,
                invoice: action.invoice,
                invoices: [...state.invoices].map((invoice) => {
                    if (action.invoice.id === invoice.id) {
                        return action.invoice;
                    }
                    return invoice;
                }),
            };

        case invoiceConstants.DELETE_INVOICE_STARTED:
            return { ...state, isFetchingInvoice: true };
        case invoiceConstants.DELETE_INVOICE_FAILURE:
            return { ...state, isFetchingInvoice: false, invoice: null, message: action.message || "" };
        case invoiceConstants.DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                isFetchingInvoice: false,
                invoice: action.invoice,
                invoices: [...state.invoices].filter((invoice) => action.invoice.id !== invoice.id),
            };
        default:
            return state;
    }
}    
    

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import UpdateBusinessPage from "@components/business/crud/UpdateBusinessPage.js";

import * as businessActions from "@actions/businessActions";
import * as navigationActions from "@actions/navigationActions";

class UpdateBusinessContainer extends PureComponent {
    render() {
        const {  changeValue, updateBusiness, popStack, callback, ...props } = this.props;
        return (
            <UpdateBusinessPage
                {...props}
                onChange={changeValue}
                updateBusiness={(business) =>
                    updateBusiness( business).then((response) => {
                        popStack && popStack();
                        callback && callback(response.business)
                    })
                }
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth.user,
        ...state.business,
        ownProps
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateBusiness: ( business) => dispatch(businessActions.updateBusiness( business)),
        changeValue: (name, value) => dispatch(businessActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(UpdateBusinessContainer));
    

import api from "../api";


// CRUD
export const get = (id = '') => {
    return api.get(`/business/${id}`);
};

export const create = (business) => {
    return api.post(`/business/business`, business);
};

export const update = (business) => {
    return api.post(`/business/update-business`, business);
};

export const remove = (business) => {
    return api.delete(`/business/business/${business.id}`);
};


// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
    return api.get(`/business/all-businesses`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
    return api.get(`/business/all-businesses`, searchParameters);
};

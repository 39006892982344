
import { maintenanceConstants } from "@constants/maintenance.constants";

const initialState = {
    isFetchingMaintenance: false,
    formMaintenance: null,
    maintenance: null,

    isFetchingMaintenances: false,
    isFetchingMaintenancesFrom: 0,
    maintenancesTotalResults: 0,
    maintenancesLoaded: 0,
    maintenances: [],
};

export default function maintenance(state = initialState, action) {
    switch (action.type) {
        case maintenanceConstants.MAINTENANCE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case maintenanceConstants.GET_MAINTENANCE_STARTED:
            return { ...state, isFetchingMaintenance: true };
        case maintenanceConstants.GET_MAINTENANCE_FAILURE:
            return { ...state, isFetchingMaintenance: false, maintenance: null, error: action.message || "" };
        case maintenanceConstants.GET_MAINTENANCE_SUCCESS:
            return {
                ...state,
                isFetchingMaintenance: false,
                maintenance: action.maintenance,
            };
            
        case maintenanceConstants.GET_MAINTENANCES_STARTED:
            return { ...state, isFetchingMaintenances: true,  isFetchingMaintenancesFrom: action.from || 0 };
        case maintenanceConstants.GET_MAINTENANCES_FAILURE:
            return { ...state, isFetchingMaintenances: false, maintenances: [], error: action.message || "" };
        case maintenanceConstants.GET_MAINTENANCES_SUCCESS:
            return {
                ...state,
                isFetchingMaintenances: false,
                maintenances: action.from > 0 ? [...state.maintenances, ...action.content] : action.content,
                maintenancesLoaded:
                    action.from > 0
                        ? state.maintenances.length + action.content.length
                        : action.content.length,
                maintenancesTotalResults: action.totalElements || 0,
            };

        case maintenanceConstants.SEARCH_MAINTENANCES_STARTED:
            return { ...state, isFetchingMaintenances: true, isFetchingMaintenancesFrom: action.from || 0  };
        case maintenanceConstants.SEARCH_MAINTENANCES_FAILURE:
            return { ...state, isFetchingMaintenances: false, maintenances: [], error: action.message || "" };
        case maintenanceConstants.SEARCH_MAINTENANCES_SUCCESS:
            return {
                ...state,
                isFetchingMaintenances: false,
                maintenances: action.from > 0 ? [...state.maintenances, ...action.content] : action.content,
                maintenancesLoaded:
                    action.from > 0
                        ? state.maintenances.length + action.content.length
                        : action.content.length,
                maintenancesTotalResults: action.totalElements || 0,
            };

        case maintenanceConstants.CREATING_MAINTENANCE_STARTED:
            return { ...state, isFetchingMaintenance: true };
        case maintenanceConstants.CREATING_MAINTENANCE_FAILURE:
            return { ...state, isFetchingMaintenance: false, maintenance: null, error: action.message || "" };
        case maintenanceConstants.CREATING_MAINTENANCE_SUCCESS:
            return {
                ...state,
                isFetchingMaintenance: false,
                maintenance: action.maintenance,
                maintenances: [action.maintenance, ...state.maintenances],
            };

        case maintenanceConstants.UPDATING_MAINTENANCE_STARTED:
            return { ...state, isFetchingMaintenance: true };
        case maintenanceConstants.UPDATING_MAINTENANCE_FAILURE:
            return { ...state, isFetchingMaintenance: false, maintenance: null, error: action.message || "" };
        case maintenanceConstants.UPDATING_MAINTENANCE_SUCCESS:
            return {
                ...state,
                isFetchingMaintenance: false,
                maintenance: action.maintenance,
                maintenances: [...state.maintenances].map((maintenance) => {
                    if (action.maintenance.id === maintenance.id) {
                        return action.maintenance;
                    }
                    return maintenance;
                }),
            };

        case maintenanceConstants.DELETE_MAINTENANCE_STARTED:
            return { ...state, isFetchingMaintenance: true };
        case maintenanceConstants.DELETE_MAINTENANCE_FAILURE:
            return { ...state, isFetchingMaintenance: false, maintenance: null, message: action.message || "" };
        case maintenanceConstants.DELETE_MAINTENANCE_SUCCESS:
            return {
                ...state,
                isFetchingMaintenance: false,
                maintenance: action.maintenance,
                maintenances: [...state.maintenances].filter((maintenance) => action.maintenance.id !== maintenance.id),
            };
        default:
            return state;
    }
}    
    

import { insuranceConstants } from "@constants/insurance.constants";

const initialState = {
    isFetchingInsurance: false,
    formInsurance: null,
    insurance: null,

    isFetchingInsurances: false,
    isFetchingInsurancesFrom: 0,
    insurancesTotalResults: 0,
    insurancesLoaded: 0,
    insurances: [],
};

export default function insurance(state = initialState, action) {
    switch (action.type) {
        case insuranceConstants.INSURANCE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case insuranceConstants.GET_INSURANCE_STARTED:
            return { ...state, isFetchingInsurance: true };
        case insuranceConstants.GET_INSURANCE_FAILURE:
            return { ...state, isFetchingInsurance: false, insurance: null, error: action.message || "" };
        case insuranceConstants.GET_INSURANCE_SUCCESS:
            return {
                ...state,
                isFetchingInsurance: false,
                insurance: action.insurance,
            };
            
        case insuranceConstants.GET_INSURANCES_STARTED:
            return { ...state, isFetchingInsurances: true,  isFetchingInsurancesFrom: action.from || 0 };
        case insuranceConstants.GET_INSURANCES_FAILURE:
            return { ...state, isFetchingInsurances: false, insurances: [], error: action.message || "" };
        case insuranceConstants.GET_INSURANCES_SUCCESS:
            return {
                ...state,
                isFetchingInsurances: false,
                insurances: action.from > 0 ? [...state.insurances, ...action.content] : action.content,
                insurancesLoaded:
                    action.from > 0
                        ? state.insurances.length + action.content.length
                        : action.content.length,
                insurancesTotalResults: action.totalElements || 0,
            };

        case insuranceConstants.SEARCH_INSURANCES_STARTED:
            return { ...state, isFetchingInsurances: true, isFetchingInsurancesFrom: action.from || 0  };
        case insuranceConstants.SEARCH_INSURANCES_FAILURE:
            return { ...state, isFetchingInsurances: false, insurances: [], error: action.message || "" };
        case insuranceConstants.SEARCH_INSURANCES_SUCCESS:
            return {
                ...state,
                isFetchingInsurances: false,
                insurances: action.from > 0 ? [...state.insurances, ...action.content] : action.content,
                insurancesLoaded:
                    action.from > 0
                        ? state.insurances.length + action.content.length
                        : action.content.length,
                insurancesTotalResults: action.totalElements || 0,
            };

        case insuranceConstants.CREATING_INSURANCE_STARTED:
            return { ...state, isFetchingInsurance: true };
        case insuranceConstants.CREATING_INSURANCE_FAILURE:
            return { ...state, isFetchingInsurance: false, insurance: null, error: action.message || "" };
        case insuranceConstants.CREATING_INSURANCE_SUCCESS:
            return {
                ...state,
                isFetchingInsurance: false,
                insurance: action.insurance,
                insurances: [action.insurance, ...state.insurances],
            };

        case insuranceConstants.UPDATING_INSURANCE_STARTED:
            return { ...state, isFetchingInsurance: true };
        case insuranceConstants.UPDATING_INSURANCE_FAILURE:
            return { ...state, isFetchingInsurance: false, insurance: null, error: action.message || "" };
        case insuranceConstants.UPDATING_INSURANCE_SUCCESS:
            return {
                ...state,
                isFetchingInsurance: false,
                insurance: action.insurance,
                insurances: [...state.insurances].map((insurance) => {
                    if (action.insurance.id === insurance.id) {
                        return action.insurance;
                    }
                    return insurance;
                }),
            };

        case insuranceConstants.DELETE_INSURANCE_STARTED:
            return { ...state, isFetchingInsurance: true };
        case insuranceConstants.DELETE_INSURANCE_FAILURE:
            return { ...state, isFetchingInsurance: false, insurance: null, message: action.message || "" };
        case insuranceConstants.DELETE_INSURANCE_SUCCESS:
            return {
                ...state,
                isFetchingInsurance: false,
                insurance: action.insurance,
                insurances: [...state.insurances].filter((insurance) => action.insurance.id !== insurance.id),
            };
        default:
            return state;
    }
}    
    
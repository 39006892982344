import api from '../api';

export const login = (username = '', password = '') => {
    return api.post(`/auth/signin`, { username, password });
};

export function register(
    username = '',
    email = '',
    password = '',
    repeatPassword = '',
    terms = false,
    newsletter = false,
    recaptcha = '',
    language = ''
) {
    return api.post(`/user/register`, {
        username: username,
        email: email,
        password: password,
        repeatPassword: repeatPassword,
        terms: terms,
        newsletter: newsletter,
        recaptcha: recaptcha,
        language: language,
    });
}

export const forgot = (email = '') => {
    return api.post(`/auth/forgot-password`, { email });
};

export const reset = (user, token, password, repeatPassword) => {
    return api.post(`/user/recover`, {
        id: user,
        token: token,
        password: password,
        repeatPassword: repeatPassword,
    });
};

export const updatePassword = ( currentPassword, newPassword, repeatPassword) => {
    return api.post(`/update-user/password`, {
        currentPassword,
        newPassword,
        repeatPassword: repeatPassword,
    });
};

export const update = (user) => {
    return api.post(`/update-user`, { ...user });
};


export function logout() {
    return api.post(`/auth/signout`);
}

export function confirmEmail(user = '', token = '') {
    return api.get(`/user/${user}/verify/${token}`);
}